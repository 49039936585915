import { useState, useEffect } from 'react'

const url_attendance = 'https://evcc-attend-api.shin.dev/tv_attendance';
const TABLE_LENGTH = 30;

const SHUFFLE_ATTENDANCE_TABLE=0;
const SHUFFLE_ABSENT_TABLE=1;

const SHUFFLE_ATTENDANCE_TABLE_TIMEOUT=60;
const SHUFFLE_ABSENT_TABLE_TIMEOUT=60;
const SHUFFLE_SWITCH_ABSENT_TABLE_THRESHOLD=20;


const AttendanceComponent = () => {
  const [attendance, setAttendance] = useState([]);
  const [absent_students, setAbsentStudents] = useState([]);
  const [shuffle, setShuffle] = useState(0);
  const [shufflecount, setShufflecount] = useState(0);
  const [attendance_count, setAttendanceCount] = useState(0);

  // load data from API
  const getData = async () => {
    try {
      const res = await fetch(url_attendance);
      let data = await res.json();
      console.log(data);

      setAttendance(data['attendance']);
      setAbsentStudents(data['absent_students']);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getData();

    const getDataCall = setInterval(() => {
      getData();
    }, 2000);

    return () => {
      // clean up
      clearInterval(getDataCall);
    };
  }, []);

  // update shuffle to attendance table if count changed
  useEffect(() => {
    if(attendance_count !== attendance.length) {
      console.log(`attendance count changed ${attendance_count} -> ${attendance.length}`);
      setAttendanceCount(attendance.length);
      setShuffle(SHUFFLE_ATTENDANCE_TABLE);
      setShufflecount(0);
    }
  }, [attendance_count, attendance.length])

  // shuffle ticker
  useEffect(() => {
    const shuffleCall = setInterval(() => {
      setShufflecount(shufflecount => shufflecount + 1);
    }, 1000);
    return () => {
      clearInterval(shuffleCall);
    };
  }, [shuffle, shufflecount]);

  // shuffle invoker
  useEffect(() => {
    if(attendance_count < SHUFFLE_SWITCH_ABSENT_TABLE_THRESHOLD && shuffle === SHUFFLE_ABSENT_TABLE && shufflecount > SHUFFLE_ABSENT_TABLE_TIMEOUT){
      setShuffle(SHUFFLE_ATTENDANCE_TABLE);
      setShufflecount(0);
    }
    
    if(attendance_count >= SHUFFLE_SWITCH_ABSENT_TABLE_THRESHOLD && shuffle === SHUFFLE_ATTENDANCE_TABLE && shufflecount > SHUFFLE_ATTENDANCE_TABLE_TIMEOUT){
      setShuffle(SHUFFLE_ABSENT_TABLE);
      setShufflecount(0);
    }
  }, [shuffle, shufflecount, attendance_count]);

  // keydown
  useEffect(() => {
    const keydownHandler = (e) => {
      if(e.code === 'Space'){
        setShuffle(shuffle => (shuffle + 1)%2);
        setShufflecount(0);
      }
    }
    document.addEventListener('keydown', keydownHandler);
    return () => {
      document.removeEventListener('keydown', keydownHandler);
    }
  }, []);

  return (<>
    {shuffle === 0 && <AttendanceTable attendance={attendance}/>}
    {shuffle === 1 && <AbsentTable absent_students={absent_students} />}
    <div className="position-absolute bottom-0 start-0 counter">{attendance.length}({[...new Set(attendance.map(a => a.student._id))].length}):{absent_students.length}:{shuffle}:{shufflecount}</div>
    </>
  )

}

const AttendanceTable = (props) => {
  let attendance = [...props.attendance];

  if (attendance.length >= TABLE_LENGTH) {
    attendance = attendance.slice(0, TABLE_LENGTH);
  }
  
  return (
    <table className="table table-dark table-striped">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Action</th>
          <th scope="col">Time</th>
        </tr>
      </thead>
      <tbody>
        {attendance.map((a, index) => {
          let timeString = "-";
          try{
            const offset = new Date(a.time).getTimezoneOffset();
            const time = new Date(new Date(a.time) - (offset*60*1000));
            timeString = time.toISOString().split('T')[1].split('.')[0];
          }catch(e){
          }
          return (
            <tr key={index}>
              <td>{a.student.firstname} {a.student.lastname}</td>
              <td>{a.action}</td>
              <td>{timeString}</td>
            </tr>
          );
        })}
        {[...Array(TABLE_LENGTH-attendance.length).keys()].map(i => {
          return (
            <tr key={i}>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          );
        })
        }
      </tbody>
    </table>
  );
}

const AbsentTable = (props) => {
  let absent_students = [...props.absent_students];

  if (absent_students.length >= TABLE_LENGTH) {
    absent_students = absent_students.slice(0, TABLE_LENGTH);
  }

  return (
    <table className="table table-dark table-striped" id="absent_table">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Class</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        {absent_students.map((a, index) => {
          return (
            <tr key={index}>
              <td>{a.firstname} {a.lastname}</td>
              <td>{a.class}</td>
              <td>Absent</td>
            </tr>
          );
        })}
        {[...Array(TABLE_LENGTH-absent_students.length).keys()].map(i => {
          return (
            <tr key={i}>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          );
        })
        }
      </tbody>
    </table>
  );
}
  
export default AttendanceComponent;  