import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AttendanceComponent from './AttendanceTable';

function App() {
  return (
    <div className="App">
      <div className="container-fluid">
        <div className="row min-vh-100">
          <div className="col-9 nopadding">
          <video loop autoPlay muted playsInline>
            <source src="orange.mp4" type="video/mp4"/>
          </video>
          </div>
          <div className="col nopadding">
            <AttendanceComponent />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
